import { template as template_c1fa818b1df54f4382a74e9b7e9c97f6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c1fa818b1df54f4382a74e9b7e9c97f6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
