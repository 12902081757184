import { template as template_f224acf93e3c483586c6405efb7fe5fa } from "@ember/template-compiler";
const FKLabel = template_f224acf93e3c483586c6405efb7fe5fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
