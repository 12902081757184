import { template as template_105399592839442799b339640776a8d7 } from "@ember/template-compiler";
const FKText = template_105399592839442799b339640776a8d7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
